
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  ComputedRef,
  onBeforeUpdate,
} from 'vue'

import { setCurrentPageTitle } from '@/core/helpers/breadcrumb'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useI18n } from 'vue-i18n'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import TransferSurveyModal from '@/components/modals/forms/TransferSurveyModal.vue'
import TableSkeleton from '@/components/widgets/skeletons/TableSkeleton.vue'
import { FolderRequest } from '../../core/types/FolderTypes'
import {
  FolderSurveyLineItemDto,
  RegenerateSurveyPdfRequestDto,
} from '@/core/types/SurveyTypes'

export default defineComponent({
  name: 'client-job-survey-folder',
  props: {
    folderid: {
      type: [String],
      default: '0',
    },
    foldername: {
      type: [String],
      default: '',
    },
    clientname: {
      type: [String],
      default: '',
    },
    clientlocationname: {
      type: [String],
      default: '',
    },
  },
  components: {
    TableSkeleton,
    TransferSurveyModal,
  },
  setup(props) {
    const store = useStore()
    const isDataReady = ref(false)
    const currentUser = store.getters.currentUser
    let displayModal = ref<boolean>(false)
    const { d } = useI18n()
    const folderRequest = ref<FolderRequest>({
      currentUserRole: currentUser.role,
      currentUserId: currentUser.id,
      folderId: Number(props.folderid),
      folderName: props.foldername,
      getAllSurveys: false,
      clientId: 0,
      clientLocationId: 0,
    })
    const folderLineItems = computed(() => {
      return store.getters.getFolderContents
    })
    const searchSurveys = ref('')

    const client = computed(() => {
      return store.getters.getClientByName(props.clientname)
    })
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo
    })
    if (props.folderid === '0') {
      folderRequest.value.folderId = currentClient.value.jobSurveyFolderId
      folderRequest.value.folderName = 'JOB-Surveys'
    }
    folderRequest.value.clientId = client.value.clientId

    const selectedSurveyId = ref(0)
    const selectedRespondentId = ref(0)

    const regenerateSurveyRequest = ref({} as RegenerateSurveyPdfRequestDto)
    const regenerateButtons = ref([] as HTMLButtonElement[])

    onMounted(() => {
      setCurrentPageTitle('Client Folder')
      setCurrentPageBreadcrumbs(props.clientname, ['Client'])
      GetClientFolderContents()
    })

    onBeforeUpdate(() => {
      regenerateButtons.value = []
    })

    const GetClientFolderContents = () => {
      folderRequest.value.clientId = client.value.clientId

      store
        .dispatch(Actions.GET_CLIENT_FOLDER_CONTENTS, folderRequest.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText:
              'There was a problem retrieving folder contents.',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          })
        })
    }

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return folderLineItems.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.surveyJobTitle
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.surveyStatus
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          )
        })
      }
    )

    watch(folderLineItems, () => {
      isDataReady.value = true
    })

    function selectedRow(surveyId, respondentId) {
      selectedSurveyId.value = surveyId
      selectedRespondentId.value = respondentId
    }

    function regeneratePdf(
      surveyId,
      surveyType,
      respondentId,
      aspNetUserId,
      index
    ) {
      regenerateSurveyRequest.value.respondentId = respondentId
      regenerateSurveyRequest.value.aspNetUserId = aspNetUserId
      regenerateSurveyRequest.value.surveyId = surveyId
      regenerateSurveyRequest.value.surveyType = surveyType

      regenerateButtons.value[index].disabled = true
      regenerateButtons.value[index].setAttribute('data-kt-indicator', 'on')

      store
        .dispatch(
          Actions.PUT_REGENERATE_SURVEY_PDF,
          regenerateSurveyRequest.value
        )
        .then(() => {
          regenerateButtons.value[index]?.removeAttribute('data-kt-indicator')
          regenerateButtons.value[index].disabled = false
          Swal.fire({
            text: 'Survey has been regenerated!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, great!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
        .catch(() => {
          regenerateButtons.value[index]?.removeAttribute('data-kt-indicator')
          regenerateButtons.value[index].disabled = false
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          })
        })
    }

    return {
      currentUser,
      currentClient,
      client,
      isDataReady,
      d,
      folderLineItems,
      GetClientFolderContents,
      searchSurveys,
      selectedSurveyId,
      selectedRespondentId,
      displayModal,
      filterSurveys,
      folderRequest,
      regeneratePdf,
      selectedRow,
      regenerateSurveyRequest,
      regenerateButtons,
    }
  },
})
